<template>
  <div>
    <a-modal
      width="1220px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑通用套餐"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row>
            <!--基本信息， 周期容量设置-->
            <a-col :span="12">
              <span class="item-title">基本信息</span>
              <a-form-item label="通用套餐名称">
                <a-input
                  v-decorator="['name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入通用套餐名称' },
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="运营商种类"
                class="simiot-display-item"
              >
                {{ data.carrier_type }}
              </a-form-item>

              <a-form-item :label="`关联运营商`">
                <a-select
                  mode="multiple"
                  placeholder="允许零售或自订时必选"
                  v-decorator="['carrier_account_ids', {
                    rules: [{ required: isAllowRetail === true || isAllowSelfSubscribe === true, message: '请选择运营商账户' }]
                  }]"
                  allow-clear
                >
                  <a-select-option
                    v-for="option in carrierAccountOptions"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item
                label="流量属性"
                class="simiot-display-item"
              >
                {{ data.traffic_property }}
              </a-form-item>

              <a-form-item
                label="用户业务模式"
                class="simiot-display-item"
              >
                {{ data.user_business_type }}
              </a-form-item>

              <a-form-item label="套餐描述">
                <a-textarea
                  v-decorator="['remark', {
                    rules: [
                      { max: 200, message: '最多200个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <span class="item-title">周期与容量</span>

              <a-form-item
                label="套餐周期"
                class="simiot-display-item"
              >
                {{ data.service_cycle }}
              </a-form-item>

              <a-form-item
                label="套餐容量"
                class="simiot-display-item"
              >
                {{ data.package_capacity }}
              </a-form-item>

              <a-form-item
                label="语音"
                class="simiot-display-item"
              >
                {{ data.voice_capacity }}
              </a-form-item>

              <div v-show="isCanSetAllowRetailOrSelfSubscribe">
                <span class="item-title">零售设置</span>

                <a-form-item label="允许零售">
                  <a-checkbox
                    :checked="isAllowRetail"
                    v-decorator="['is_allow_retail']"
                    @change="changeIsAllowRetail"
                  /> 允许移动端订购
                </a-form-item>

                <a-form-item label="零售价设置者" v-show="isAllowRetail">
                  <a-radio-group
                    v-decorator="['retail_operator', { initialValue: data.retail_operator }]"
                    @change="changeRetailOperator"
                  >
                    <a-radio value="platform">
                      平台
                    </a-radio>
                    <a-radio value="user">
                      零售商
                    </a-radio>
                  </a-radio-group>
                  <font style="color:red;">修改将导致价格变动，谨慎!</font>
                </a-form-item>
              </div>

              <div v-show="isCanSetAllowRetailOrSelfSubscribe">
                <span class="item-title">其它设置</span>

                <a-form-item label="允许自订">
                  <a-checkbox
                    :checked="isAllowSelfSubscribe"
                    v-decorator="['is_allow_self_subscribe']"
                    @change="changeIsAllowSelfSubscribe"
                  /> 允许CMP订购
                </a-form-item>
              </div>
            </a-col>

            <!--价格设置-->
            <a-col :span="12">
              <span class="item-title">价格设置</span>
              <div class="simiot-table-operation-buttons">
                <a-space>
                  <a-button type="primary" @click="addSetPriceItem"> 新增 </a-button>
                </a-space>
              </div>

              <a-row style="margin-top: 10px; margin-bottom: 10px" :gutter="24">
                <a-col :span="favorablePriceSpanSize">
                  周期数*
                </a-col>
                <a-col :span="favorablePriceSpanSize">
                  标准价(元)*
                </a-col>
                <a-col :span="favorablePriceSpanSize">
                  底价(元)*
                </a-col>
                <a-col :span="favorablePriceSpanSize" v-if="isAllowRetail && isPlatformRetailOperator">
                  零售价(元)
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="(k, index) in form.getFieldValue('keys')"
                :key="k"
              >
                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-input-number
                      :min="1"
                      :max="10000000"
                      style="width: 120px"
                      @change="validateFavorablePriceCycles"
                      v-decorator="[`base_product_favorable_prices[${k}].cycles`, {
                        initialValue: base_product_favorable_prices[k] && base_product_favorable_prices[k].cycles,
                        rules: [
                          { required: true, message: '请输入周期数' },
                          { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },
                          { validator: (rule, value, callback) => checkCycles(rule, value, callback, k, index) }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      v-decorator="[`base_product_favorable_prices[${k}].distributor_price`, {
                        initialValue: base_product_favorable_prices[k] && base_product_favorable_prices[k].distributor_price,
                        rules: [
                          { required: true, message: '请输入标准价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize">
                  <a-input-group compact>
                    <a-form-item>
                      <a-space>
                        <a-input-number
                          :min="0"
                          :max="10000000"
                          :step="0.01"
                          :precision="2"
                          style="width: 120px"
                          v-decorator="[`base_product_favorable_prices[${k}].floor_price`, {
                            initialValue: base_product_favorable_prices[k] && base_product_favorable_prices[k].floor_price,
                            rules: [
                              { required: true, message: '请输入底价' },
                              { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                            ]
                          }]"
                        />
                        <a-icon
                          :style="{ display: 'inline-block'}"
                          class="dynamic-delete-button"
                          type="minus-circle-o"
                          v-show="form.getFieldValue('keys').length > 1 && !isPlatformRetailOperator"
                          :disabled="form.getFieldValue('keys').length === 1"
                          @click="removeSetPriceItem(k)"
                        />
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-col>

                <a-col :span="favorablePriceSpanSize" v-if="isAllowRetail && isPlatformRetailOperator">
                  <a-input-group compact>
                    <a-form-item>
                      <a-space>
                        <a-input-number
                          :min="0"
                          :max="10000000"
                          :step="0.01"
                          :precision="2"
                          style="width: 120px"
                          v-decorator="[`base_product_favorable_prices[${k}].retail_price`, {
                            initialValue: base_product_favorable_prices[k] && base_product_favorable_prices[k].retail_price,
                            rules: [
                              { type: 'number', message: '请输入 0 - 10000000 之间的数字' },
                              { validator: (rule, value, callback) => checkRetailPrice(rule, value, callback, k) }
                            ]
                          }]"
                        />
                        <a-icon
                          :style="{ display: 'inline-block'}"
                          class="dynamic-delete-button"
                          type="minus-circle-o"
                          v-show="form.getFieldValue('keys').length > 1"
                          :disabled="form.getFieldValue('keys').length === 1"
                          @click="removeSetPriceItem(k)"
                        />
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
                <a-col :span="8">
                  超额计费单位*
                </a-col>
                <a-col :span="8">
                  超额标准价(元)*
                </a-col>
                <a-col :span="8">
                  超额底价(元)*
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
                <a-col :span="8">
                  <a-input-group compact>
                    <a-form-item>
                      <a-space>
                        <a-input-number
                          style="width: 120px"
                          :min="1"
                          :max="10000000"
                          v-decorator="['excess_rate_quantity', {
                            rules: [
                              { required: isShowExcessRateItem, message: '请输入超额计费单位' },
                              { type: 'integer', message: '请输入 1 - 10000000 之间的整数' }
                            ]
                          }]"
                        />
                        <span>{{ excessRateQuantityUnit }}</span>
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px;"
                      v-decorator="['excess_distributor_price', {
                        rules: [
                          { required: isShowExcessRateItem, message: '请输入超额标准价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      v-decorator="['excess_floor_price', {
                        rules: [
                          { required: isShowExcessRateItem, message: '请输入超额底价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowStopItem">
                <a-col :span="8">
                  停机保号标准价(元)*
                </a-col>
                <a-col :span="8">
                  停机保号底价(元)*
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowStopItem">
                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px;"
                      v-decorator="['stop_distributor_price', {
                        rules: [
                          { required: isShowStopItem, message: '请输入停机保号标准价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      v-decorator="['stop_floor_price', {
                        rules: [
                          { required: isShowStopItem, message: '请输入停机保号底价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <span class="item-title">卡片设置</span>
              <a-row style="margin: 10px 0 10px 9px" :gutter="24">
                <a-col :span="8">
                  卡片材质*
                </a-col>
                <a-col :span="8">
                  标准价(元)*
                </a-col>
                <a-col :span="8">
                  底价(元)*
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="(baseProductCardType, index) in baseProductsCardTypes"
                :key="'baseProductCardType-'+ index"
              >

                <a-col :span="8">
                  <a-form-item>
                    <a-row style="width: 150px">
                      <a-col :span="4">
                        <a-checkbox-group
                          v-decorator="[`base_products_card_types[${index}].card_type_id`,
                                        { initialValue: [baseProductCardType.base_products_card_type_id] }]"
                          @change="(checked) => changeCardType(checked, index)"
                          style="width: 155px"
                        >
                          <a-checkbox :value="baseProductCardType.card_type_id" />
                        </a-checkbox-group>
                      </a-col>
                      <a-col :span="20">
                        {{ baseProductCardType.card_type_name }}
                      </a-col>
                    </a-row>
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      v-decorator="[`base_products_card_types[${index}].distributor_price`, {
                        initialValue: baseProductCardType.id > 0 ? baseProductCardType.distributor_price : undefined,
                        rules: [
                          { required: cardTypePriceRequired[index], message: '请输入标准价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      v-decorator="[`base_products_card_types[${index}].floor_price`, {
                        initialValue: baseProductCardType.id > 0 ? baseProductCardType.floor_price : undefined,
                        rules: [
                          { required: cardTypePriceRequired[index], message: '请输入底价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-empty v-if="baseProductsCardTypes.length === 0" />

            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findCarrierAccountOptions } from '@/api/common_options'
import { findBaseProductForm, updateBaseProduct } from '@/api/base_product'

export default {
  name: 'EditBaseProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'base_product' }),
      submitting: false,
      spinning: false,
      capacityUnitOptions: [
        { name: 'MB', value: 'mb' },
        { name: 'GB', value: 'gb' }
      ],
      excessRateQuantityUnit: 'MB',
      isShowExcessRateItem: true, // 是否显示设置超额参数一项
      isShowStopItem: false, // 是否显示设置停机保号参数一项
      userBusinessType: 'custom_month',
      data: {},
      base_product_favorable_prices: [],
      priceIndex: 1,
      favorablePriceSpanSize: 8,
      isAllowRetail: false, // 是否允许零售
      isCanSetAllowRetailOrSelfSubscribe: false, // 是否可设置零售配置
      isPlatformRetailOperator: false, // 零售价设置者是否是平台
      isAllowSelfSubscribe: false, // 是否可设置自订配置
      carrierAccountOptions: [], // 运营商账号
      loadingCarrierAccountOptions: false,
      baseProductsCardTypes: [], // 卡片设置
      cardTypePriceRequired: [] // 卡片价格必选数组
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.form.getFieldDecorator('keys', { initialValue: [0], preserve: true })
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.spinning = true
      findBaseProductForm(this.id).then((res) => {
        this.data = res.data
        this.userBusinessType = this.data.user_business_type_slug
        this.isAllowRetail = this.data.is_allow_retail
        this.isPlatformRetailOperator = this.data.retail_operator === 'platform'
        this.favorablePriceSpanSize = (this.isAllowRetail && this.isPlatformRetailOperator) ? 6 : 8
        this.base_product_favorable_prices = res.data.base_product_favorable_prices
        this.priceIndex = this.base_product_favorable_prices.length
        this.isAllowSelfSubscribe = this.data.is_allow_self_subscribe
        const keys = []
        for (let i = 0; i < this.base_product_favorable_prices.length; i++) {
          keys.push(i)
        }
        this.form.setFieldsValue({
          keys: keys
        })
        this.baseProductsCardTypes = res.data.base_products_card_types
        this.baseProductsCardTypes.forEach((cardType) => {
          this.cardTypePriceRequired.push(cardType.id > 0)
        })

        this.form.setFieldsValue({
          name: this.data.name,
          is_allow_retail: this.data.is_allow_retail,
          retail_operator: this.data.retail_operator,
          is_allow_self_subscribe: this.data.is_allow_self_subscribe,
          remark: this.data.remark,
          excess_rate_quantity: this.data.excess_rate_quantity,
          excess_distributor_price: this.data.excess_distributor_price,
          excess_floor_price: this.data.excess_floor_price,
          stop_distributor_price: this.data.stop_distributor_price,
          stop_floor_price: this.data.stop_floor_price
        })

        if (this.data.capacity_unit === 'count') {
          this.excessRateQuantityUnit = '次'
        }

        if (this.userBusinessType === 'custom_day' || this.userBusinessType === 'merchant_sales') {
          this.isShowExcessRateItem = false
        }

        if (this.userBusinessType === 'custom_month' || this.userBusinessType === 'traffic_share') {
          this.isShowStopItem = true
        }

        // 以下模式可以配置零售或自订
        if (this.userBusinessType !== 'traffic_share_package' && this.userBusinessType !== 'merchant_sales') {
          this.isCanSetAllowRetailOrSelfSubscribe = true
        }

        this.spinning = false

        this.getCarrierTypeAccounts(this.data.carrier_type_slug, this.data.carrier_account_ids)
      })
    },

    // 改变是否允许零售
    changeIsAllowRetail(e) {
      if (e.target.checked) {
        this.isAllowRetail = true
      } else {
        this.isAllowRetail = false
      }

      this.favorablePriceSpanSize = (this.isAllowRetail && this.isPlatformRetailOperator) ? 6 : 8
    },

    changeRetailOperator(e) {
      if (e.target.value === 'platform') {
        this.isPlatformRetailOperator = true
      } else {
        this.isPlatformRetailOperator = false
      }

      this.favorablePriceSpanSize = (this.isAllowRetail && this.isPlatformRetailOperator) ? 6 : 8
    },

    // 改变是否允许自订
    changeIsAllowSelfSubscribe(e) {
      if (e.target.checked) {
        this.isAllowSelfSubscribe = true
      } else {
        this.isAllowSelfSubscribe = false
      }
    },

    // 运营商种类变更，加载运营商账户和运营商状态
    getCarrierTypeAccounts(carrierTypeSlug, carrierAccountIds) {
      this.loadingCarrierAccountOptions = true
      // 清空选项
      this.carrierAccountOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        carrier_account_ids: undefined
      })
      this.directionalShow = false

      findCarrierAccountOptions(carrierTypeSlug).then((res) => {
        if (res.code === 0) {
          this.loadingCarrierAccountOptions = false
          this.carrierAccountOptions = res.data

          if (carrierAccountIds) {
            this.form.setFieldsValue({
              carrier_account_ids: carrierAccountIds
            })
          }
        }
      })
    },

    checkRetailPrice(rule, value, callback, k) {
      const distributorPrice = this.form.getFieldValue(`base_product_favorable_prices[${k}].distributor_price`)
      if (value < distributorPrice) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于标准价!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkCycles(rule, value, callback, k, index) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback()
        return
      }

      const preCycleIndex = this.form.getFieldValue('keys')[index - 1]
      const preCycles = this.form.getFieldValue(`base_product_favorable_prices[${preCycleIndex}].cycles`)
      if (preCycles && preCycles >= value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('应比上个周期数大!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 校验优惠价周期
    validateFavorablePriceCycles() {
      this.form.getFieldValue('keys').forEach((k) => {
        if (this.form.getFieldValue(`base_product_favorable_prices[${k}].cycles`)) {
          this.$nextTick(() => {
            this.form.validateFields([`base_product_favorable_prices[${k}].cycles`], { force: true })
          })
        }
      })
    },

    removeSetPriceItem(k) {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      // We need at least one passenger
      if (keys.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter(key => key !== k)
      })

      this.validateFavorablePriceCycles()
    },

    addSetPriceItem() {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      const nextKeys = keys.concat(this.priceIndex++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys: nextKeys
      })
    },

    changeCardType(value, index) {
      this.cardTypePriceRequired[index] = value.length !== 0

      if (value.length === 0) {
        this.$nextTick(() => {
          this.form.validateFields([`base_products_card_types[${index}].distributor_price`], { force: true })
          this.form.validateFields([`base_products_card_types[${index}].floor_price`], { force: true })
        })
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      this.form.validateFields((err, values) => {
        if (!err) {
          const baseProductsCardTypes = []
          if (this.baseProductsCardTypes.length > 0) {
            values.base_products_card_types.forEach((cardType) => {
              if (cardType.card_type_id && cardType.card_type_id[0]) {
                baseProductsCardTypes.push({
                  ...cardType,
                  card_type_id: cardType.card_type_id[0]
                })
              }
            })

            if (baseProductsCardTypes.length === 0) {
              this.$error({
                title: '提示',
                content: '请勾选卡片材质'
              })
              this.submitting = false
              return
            }
          } else {
            this.$error({
              title: '提示',
              content: '当前无卡片材质可勾选，请先维护卡片材质！'
            })
            this.submitting = false
            return
          }

          const data = {
            ...values,
            base_product_favorable_prices: values.base_product_favorable_prices.filter(key => key),
            base_products_card_types: baseProductsCardTypes
          }

          updateBaseProduct(this.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.item-title {
  color: @primary-color;
}
</style>
